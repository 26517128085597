import { selector } from "recoil";

import { RequestFilter } from "@/api/generatedTypes";
import { appliedFiltersWithoutStores } from "@/components/filter/appliedFiltersWithoutStores";
import { appliedSearchState } from "@/components/filter/appliedSearchState";

import { categoriesSelectState } from "./categoriesSelectState";
import { secondFilterSelectedState } from "./secondFilterSelectedState";
import { storesSelectState } from "./storesSelectState";

export const selectedFiltersSelector = selector<RequestFilter>({
  key: "selectedFiltersSelector",
  get: ({ get }) => {
    const categories = get(categoriesSelectState);
    const stores = get(storesSelectState);
    const secondFilter = get(secondFilterSelectedState);
    const search = get(appliedSearchState);
    const appliedFilters = get(appliedFiltersWithoutStores);

    return {
      categories,
      stores,
      ...secondFilter,
      search,
      collections: appliedFilters.collections as string[],
    };
  },
});
