import { setCookie } from "nookies";
import { SetterOrUpdater } from "recoil";

import {
  FilterCategory,
  FilterColor,
  FilterDiscount,
  FilterSize,
  FilterSizeCategory,
  FilterStore,
} from "@/api/generatedTypes";

import { SecondFilterSelect } from "./secondFilterSelectedState";

export const setOnBoardingCookie = (
  numberOfDays: number,
  cookieName: string,
  cookieValue: string
) => {
  setCookie(null, cookieName, cookieValue, {
    maxAge: numberOfDays * 24 * 60 * 60,
    path: "/",
    sameSite: "lax",
  });
};

export const simpleSingleSelect = <IdType>(
  id: IdType,
  setState: SetterOrUpdater<IdType[]>
) => {
  setState((state) => {
    if (!state.includes(id)) return [...state, id];

    return state.filter((item) => item !== id);
  });
};

export const simpleMultipleSelect = <IdType>(
  ids: IdType[],
  setState: SetterOrUpdater<IdType[]>
) => {
  setState((state) => {
    if (ids.every((item) => state.includes(item))) {
      return state.filter((item) => !ids.includes(item));
    }
    if (ids.some((item) => state.includes(item))) {
      const currentStateWithAllNewItem = state.concat(ids);
      const currentStateWithoutDuplicants = new Set(currentStateWithAllNewItem);

      return Array.from(currentStateWithoutDuplicants);
    }

    return state.concat(ids);
  });
};

export const getAllCategoriesIds = (category?: FilterCategory): string[] => {
  if (!category?.children?.length) return [];
  const ids = category.children.map((item) => {
    if (item.disabled) return undefined;
    if (!item?.children?.length) return item.id;
    return [
      item.id,
      ...item.children.map((childItem) =>
        !childItem.disabled ? childItem.id : undefined
      ),
    ];
  });

  const flattenIds = ids.flat();
  const filteredIds = flattenIds.filter((item) => item) as string[];

  return filteredIds;
};

export const getGroupedStores = (stores: FilterStore[]) => {
  return stores.reduce(
    (acc: { [key: string]: FilterStore[] }, currentStore) => {
      const { city } = currentStore;
      if (city === "Интернет магазин" || !city) return acc;
      if (!acc[city]) acc[city] = [];
      acc[city].push(currentStore);
      return acc;
    },
    {}
  );
};

export const getStoresCategories = (groupedStores: {
  [key: string]: FilterStore[];
}) => {
  return Object.keys(groupedStores).map((key) => {
    return {
      name: key,
      subItems: groupedStores[key],
      count: groupedStores[key].reduce(
        (acc, cur) => (acc += cur.count || 0),
        0
      ),
      subItemsLength: groupedStores[key].length,
    };
  });
};

export const getCategoriesNames = (
  categories: FilterCategory[]
): { [key: string]: string } => {
  const mainCategoriesFlatten = categories
    .map((item) => [item].concat(item.children || []))
    .flat() as FilterCategory[];

  const mainCategoriesIdNames = mainCategoriesFlatten.reduce(
    (acc: string[][], curr) => {
      if (!curr.children?.length) return acc.concat([[curr.id, curr.name]]);
      return acc.concat(
        [[curr.id, curr.name]],
        curr.children.map((item) => [item.id, item.name])
      );
    },
    []
  );

  return Object.fromEntries(mainCategoriesIdNames);
};

export const getMainCategoriesObject = (
  categories?: FilterCategory[]
): { [key: string]: string[] } => {
  if (!categories) return {};

  const mainCategoriesFlatten = categories.map((item) => item.children).flat();

  const mainCategoriesEnabledOnly = mainCategoriesFlatten.filter(
    (item) => !item?.disabled
  );

  const mainCategoriesIds = mainCategoriesEnabledOnly?.map((item) => [
    item?.id,
    item?.children?.filter((item) => !item.disabled).map((item) => item.id),
  ]);

  return Object.fromEntries(mainCategoriesIds);
};

export type Tab = keyof SecondFilterSelect | "stores";
export interface Tabs {
  value: Tab;
  label: string;
}

export const tabs: Tabs[] = [
  {
    value: "sizes",
    label: "размер",
  },
  {
    value: "colors",
    label: "цвет",
  },
  {
    value: "discounts",
    label: "скидка",
  },
  {
    value: "stores",
    label: "магазины",
  },
];

type FilterItemType = FilterSize | FilterStore | FilterDiscount | FilterColor;

export const skipDisabled = <T extends FilterItemType>(filterItems: T[]) => {
  return filterItems.filter((filterItem) => !filterItem.disabled);
};

export const skipDisabledSizes = (sizeCategories: FilterSizeCategory[]) => {
  return sizeCategories
    .map((sizeCategory) => ({
      ...sizeCategory,
      sizes: skipDisabled(sizeCategory.sizes),
    }))
    .filter((sizeCategory) => sizeCategory.sizes.length);
};

export const skipDisabledSizeCategories = (
  sizeCategories: FilterSizeCategory[]
) => {
  return sizeCategories.filter((sizeCategory) => {
    return sizeCategory.sizes.filter((size) => !size.disabled).length > 0;
  });
};

export const getDiscountDisplayName = (discountValue?: string | number) => {
  if (
    typeof discountValue === "string" &&
    discountValue.toLowerCase() === "one_plus_one"
  ) {
    return "1+1=3";
  }
  return `${discountValue}%`;
};
