import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

import FilterApi from "@/api/FilterApi";
import { RequestFilter } from "@/api/generatedTypes";
import { ymUseFilterGoal } from "@/components/YandexMetrica/ymUseFilterGoal";

import { appliedFiltersWithoutStores } from "./appliedFiltersWithoutStores";
import { appliedSearchState } from "./appliedSearchState";
import { appliedStoresState } from "./appliedStoresState";
import { categoriesSelectState } from "./categoriesSelectState";
import {
  getMainCategoriesObject,
  setOnBoardingCookie,
  simpleMultipleSelect,
  simpleSingleSelect,
} from "./filterHelpers";
import {
  SecondFilterSelect,
  secondFilterSelectedState,
} from "./secondFilterSelectedState";
import { selectedFiltersSelector } from "./selectedFiltersSelector";
import { storesSelectState } from "./storesSelectState";

export const useFilterHandlers = () => {
  const selectedFilters = useRecoilValue(selectedFiltersSelector);
  const appliedFilters = useRecoilValue(appliedFiltersWithoutStores);
  const appliedStores = useRecoilValue(appliedStoresState);
  const appliedSearch = useRecoilValue(appliedSearchState);

  const setStoresSelect = useSetRecoilState(storesSelectState);
  const setCategoriesSelect = useSetRecoilState(categoriesSelectState);
  const setSecondFilterSelect = useSetRecoilState(secondFilterSelectedState);
  const setAppliedStores = useSetRecoilState(appliedStoresState);
  const setAppliedFilters = useSetRecoilState(appliedFiltersWithoutStores);
  const setAppliedSearch = useSetRecoilState(appliedSearchState);
  const resetCategoriesSelect = useResetRecoilState(categoriesSelectState);
  const resetSecondFilterSelect = useResetRecoilState(
    secondFilterSelectedState
  );
  const resetAppliedFilters = useResetRecoilState(appliedFiltersWithoutStores);
  const resetAppliedStores = useResetRecoilState(appliedStoresState);
  const resetAppliedSearch = useResetRecoilState(appliedSearchState);

  const router = useRouter();

  const filterQuery = useQuery(
    ["filterQuery", selectedFilters],
    () => FilterApi.filter(selectedFilters),
    {
      keepPreviousData: true,
    }
  );

  const mainCategoriesObject = getMainCategoriesObject(
    filterQuery.data?.data.categories
  );

  const selectCategory = (categoryId: string) => {
    const isParentId = Object.keys(mainCategoriesObject).includes(categoryId);

    if (isParentId) {
      return setCategoriesSelect((state) => {
        if (state.includes(categoryId))
          return state.filter((item) => item !== categoryId);

        return [
          ...state.filter(
            (item) => !mainCategoriesObject[categoryId].includes(item)
          ),
          categoryId,
        ];
      });
    }

    const parentId = Object.keys(mainCategoriesObject).reduce((acc, curr) => {
      if (mainCategoriesObject[curr].includes(categoryId)) return curr;
      return acc;
    }, "");

    return setCategoriesSelect((state) => {
      if (state.includes(categoryId))
        return state.filter((item) => item !== categoryId);
      if (state.includes(parentId)) {
        const categoriesToAdd = mainCategoriesObject[parentId].filter(
          (item) => item !== categoryId
        );
        const stateWithoutParentCategory = state.filter(
          (item) => item !== parentId
        );
        return [...stateWithoutParentCategory, ...categoriesToAdd];
      }

      const isLastSubCategory = mainCategoriesObject[parentId].every((item) =>
        [...state, categoryId].includes(item)
      );

      if (isLastSubCategory) {
        return [
          ...state.filter(
            (item) => !mainCategoriesObject[parentId].includes(item)
          ),
          parentId,
        ];
      }
      return [...state, categoryId];
    });
  };

  const selectSingleStore = (storeId: string) => {
    simpleSingleSelect(storeId, setStoresSelect);
  };

  const selectAllStores = (storesIds: string[]) => {
    simpleMultipleSelect(storesIds, setStoresSelect);
  };

  const selectSingleCategory = (categoryId: string) => {
    simpleSingleSelect(categoryId, setCategoriesSelect);
  };

  const selectMultipleCategories = (categoriesIds: string[]) => {
    simpleMultipleSelect(categoriesIds, setCategoriesSelect);
  };

  const toggleFilterState = (
    state: { [p: string]: (string | number)[] },
    categoryId: string | number,
    categoryKey: keyof SecondFilterSelect
  ) => {
    if (!state?.[categoryKey]) return state;

    if (!state[categoryKey].includes(categoryId))
      return { ...state, [categoryKey]: [...state[categoryKey], categoryId] };

    return {
      ...state,
      [categoryKey]: state[categoryKey].filter((item) => item !== categoryId),
    };
  };

  const selectSingleSecondFilter = (
    categoryId: string | number,
    categoryKey: keyof SecondFilterSelect
  ) => {
    setSecondFilterSelect((state) =>
      toggleFilterState(state, categoryId, categoryKey)
    );
  };

  const selectAndApplySingleSecondFilter = (
    categoryId: string | number,
    categoryKey: keyof SecondFilterSelect
  ) => {
    const updatedFilters = toggleFilterState(
      selectedFilters as { [p: string]: (string | number)[] },
      categoryId,
      categoryKey
    );
    setSecondFilterSelect(updatedFilters);
    applySecondFilters(
      updatedFilters.sizes,
      updatedFilters.colors,
      updatedFilters.discouts,
      updatedFilters
    );
  };

  const selectMultipleSecondFilters = (
    categoryIds: (string | number)[],
    categoryKey: keyof SecondFilterSelect
  ) => {
    setSecondFilterSelect((state) => {
      if (!state?.[categoryKey]) return state;

      const isSomeSelected = categoryIds.some((item) =>
        state[categoryKey].includes(item)
      );

      const isAllSelected = categoryIds.every((item) =>
        state[categoryKey].includes(item)
      );

      if (isAllSelected) {
        return {
          ...state,
          [categoryKey]: state[categoryKey].filter(
            (item) => !categoryIds.includes(item)
          ),
        };
      }

      if (isSomeSelected) {
        const currentCategoryState = state[categoryKey];
        const stateWithNewElements = Array.from(
          new Set([...currentCategoryState, ...categoryIds])
        );

        return { ...state, [categoryKey]: stateWithNewElements };
      }

      return { ...state, [categoryKey]: categoryIds };
    });
  };

  const applyStores = (storesIds: string[]) => {
    setOnBoardingCookie(30, "cityOnBoardingStores", JSON.stringify(storesIds));
    setAppliedStores(storesIds);
    ymUseFilterGoal();
  };

  const setJustOneFilter = (ids: string[] | number[], filterName: string) => {
    const initFilters = {
      categories: [],
      collections: [],
      sizes: [],
      colors: [],
      discounts: [],
    };
    setAppliedFilters(() => ({
      ...initFilters,
      [filterName]: ids,
    }));
    resetAppliedSearch();
    resetSecondFilterSelect();
    ymUseFilterGoal();
  };

  const applyCategories = (categoriesIds: string[]) => {
    setJustOneFilter(categoriesIds, "categories");

    router.push({
      pathname: "/filter",
      query: {
        filter: JSON.stringify({
          categories: categoriesIds,
          stores: undefined,
          search: "",
        }),
      },
    });
  };

  const applyCollections = (collectionIds: string[]) => {
    setJustOneFilter(collectionIds, "collections");

    router.push({
      pathname: "/filter",
      query: {
        filter: JSON.stringify({
          collections: collectionIds,
          stores: undefined,
          search: "",
        }),
      },
    });
  };

  const applySecondFilters = (
    sizes: (string | number)[],
    colors: (string | number)[],
    discounts: (string | number)[],
    customSelectedFilters: RequestFilter | undefined = undefined
  ) => {
    setAppliedFilters((state) => ({
      ...state,
      sizes,
      colors,
      discounts,
    }));

    const filters = customSelectedFilters || selectedFilters;

    router.push({
      pathname: "/filter",
      query: {
        filter: JSON.stringify({
          ...filters,
          stores: undefined,
          ...(appliedSearch && { search: appliedSearch }),
        }),
      },
    });
    ymUseFilterGoal();
  };

  const applySearch = (searchQuery: string) => {
    setAppliedSearch(searchQuery);
    resetAppliedFilters();
    resetAppliedStores();
    resetCategoriesSelect();
    resetSecondFilterSelect();
    router.push({
      pathname: "/filter",
      query: {
        filter: JSON.stringify({ search: searchQuery }),
      },
    });
  };

  const applyOnePlusOne = () => {
    setAppliedFilters((state) => ({
      ...state,

      discounts: ["one_plus_one"],
    }));
    router.push({
      pathname: "/filter",
      query: {
        filter: JSON.stringify({
          ...selectedFilters,
          discounts: ["one_plus_one"],
        }),
      },
    });
  };

  const resetMainFilters = () => {
    resetCategoriesSelect();
    resetSecondFilterSelect();
    resetAppliedFilters();

    router.push({
      pathname: "/filter",
    });
  };

  const resetSecondFilterCategory = (categoryKey: keyof SecondFilterSelect) => {
    setSecondFilterSelect((state) => ({ ...state, [categoryKey]: [] }));
    setAppliedFilters((state) => ({ ...state, [categoryKey]: [] }));
  };

  const resetStores = () => {
    resetAppliedStores();
    setAppliedStores([]);
    setStoresSelect([]);
    setOnBoardingCookie(30, "cityOnBoardingStores", "[]");
  };

  const resetToAppliedCategories = () => {
    setCategoriesSelect(appliedFilters.categories as string[]);
  };
  const resetToAppliedStores = () => {
    setStoresSelect(appliedStores);
  };
  const resetToAppliedSecondFilter = () => {
    setSecondFilterSelect({
      sizes: appliedFilters.sizes,
      colors: appliedFilters.colors,
      discounts: appliedFilters.discounts,
    });
    setStoresSelect(appliedStores);
  };

  const resetAllExceptStores = () => {
    resetAppliedFilters();
    resetSecondFilterSelect();
    resetCategoriesSelect();
    resetAppliedSearch();
    router.push({
      pathname: "/",
    });
  };

  const resetSearch = () => {
    resetAppliedSearch();
    router.push({
      pathname: "/",
    });
  };

  const setInitialFilters = ({
    categories = [],
    collections = [],
    sizes = [],
    colors = [],
    discounts = [],
    stores = [],
    search = "",
  }: RequestFilter) => {
    setCategoriesSelect(categories);
    setSecondFilterSelect({ sizes, colors, discounts });
    setStoresSelect(stores);
    setAppliedFilters({
      categories,
      collections,
      sizes,
      colors,
      discounts,
    });
    setAppliedStores(stores);
    setAppliedSearch(search);
  };

  const checkSecondaryFiltersApplied = () => {
    return Boolean(
      ["sizes", "discounts", "colors"].find((filterKey) => {
        return appliedFilters[filterKey]?.length;
      })
    );
  };

  return {
    selectCategory,
    selectSingleStore,
    selectAllStores,
    selectSingleCategory,
    selectMultipleCategories,
    selectSingleSecondFilter,
    selectAndApplySingleSecondFilter,
    selectMultipleSecondFilters,
    applyStores,
    applyCategories,
    applyCollections,
    applySecondFilters,
    applySearch,
    applyOnePlusOne,
    resetMainFilters,
    resetSecondFilterCategory,
    resetStores,
    resetToAppliedCategories,
    resetToAppliedStores,
    resetToAppliedSecondFilter,
    resetAllExceptStores,
    resetSearch,
    setInitialFilters,
    selectedFilters,
    appliedFilters,
    appliedSearch,
    filterQuery,
    checkSecondaryFiltersApplied,
  };
};
