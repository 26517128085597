import { atom } from "recoil";

export const appliedFiltersWithoutStores = atom<{
  [key: string]: (string | number)[];
}>({
  key: "appliedFiltersWithoutStores",
  default: {
    categories: [],
    collections: [],
    sizes: [],
    colors: [],
    discounts: [],
  },
});
