import { atom } from "recoil";

export interface SecondFilterSelect {
  sizes: string[];
  colors: string[];
  discounts: number[];
}

export const secondFilterSelectedState = atom<{
  [key: string]: (string | number)[];
}>({
  key: "secondFilterSelectedState",
  default: {
    sizes: [],
    colors: [],
    discounts: [],
  },
});
